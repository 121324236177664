import { Dispatch } from "redux";
import axios from "utils/axios";
import types from "./types";
import { setAlert } from "./alert";
import { AcademicbatchActions } from "redux/types/academicbatch";
import { AlertActions } from "redux/types/alert";

// REGISTER ACADEMICBATCH
let orgId = localStorage.getItem("orgId")
export const registerAcademicbatch =
    (body: any, history: any) =>
        async (dispatch: Dispatch<AcademicbatchActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.post(`academicbatch/create`, body, config);
                dispatch({
                    type: types.ACADEMICBATCH_REGISTER_SUCCESS,
                    payload: data,
                });
                if (data?.[0]?.info === true){
                    dispatch<any>(
                        setAlert({
                            msg: data?.[0]?.message,
                            status: 400,
                            alertType: "danger",
                        })
                    );
                } else {
                dispatch<any>(
                    setAlert({
                        msg: "Academic Batch Register successful!",
                        status: 200,
                        alertType: "success",
                    })
                );
                }
            } catch (error: any) {
                dispatch({ type: types.ACADEMICBATCH_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: error?.response?.data,
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };
// GET ACADEMICBATCHs

export const getAcademicbatchs =
    (body: any, setIsLoading?: any, initial?: boolean) => async (dispatch: Dispatch<AcademicbatchActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            if(initial){
                dispatch({type : types.ACADEMICBATCH_INITIAL})
            } else {
                dispatch({type : types.ACADEMICBATCH_NOT_INITIAL})
            }
            const { data } = await axios.post(`academicbatch/getAcademicBatches`, body, config)
            dispatch({ type: types.GET_ACADEMICBATCHS, payload: data });
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when fetching the Academic Batches!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        } finally {
            if (setIsLoading)
                setIsLoading(false);
        }
    };

// UPDATE ACADEMICBATCH DATA
export const updateAcademicbatchs =
    (body: any, id: any, history: any) =>
        async (dispatch: Dispatch<AcademicbatchActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.put(
                    `academicbatch/update/${id}`,
                    body,
                    config
                );
                dispatch({
                    type: types.UPDATE_ACADEMICBATCH,
                    payload: data,
                });
                
                dispatch<any>(
                    setAlert({
                        msg: "Academic Batch Data Updated!",
                        status: 200,
                        alertType: "success",
                    })
                );

            } catch (error: any) {
                dispatch<any>(
                    setAlert({
                        msg: "Something went wrong when updating the Academicbatch!",
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            } finally {
                // setSubmitting(false);
            }
        };

// DELETE ACADEMICBATCH
export const deleteAcademicbatch =
    (id: number) => async (dispatch: Dispatch<AcademicbatchActions | AlertActions>) => {
        const config: any = {
            header: {
                "Content-Type": "application/json",
            },
        };

        try {
            await axios.delete(`academicbatch/delete/${id}`, config);
            dispatch({ type: types.DELETE_ACADEMICBATCH, payload: id });
            dispatch<any>(
                setAlert({
                    msg: "academicbatch has been deleted!",
                    status: 200,
                    alertType: "success",
                })
            );
            dispatch<any>(getAcademicbatchs({ orgId: orgId }));
        } catch (error: any) {
            dispatch<any>(
                setAlert({
                    msg: "Something went wrong when deleting the Academicbatch!",
                    status: error?.response?.status,
                    alertType: "danger",
                })
            );
        }
    };
export const updateCurrentAcademicYear =
    (body: any, history: any) =>
        async (dispatch: Dispatch<AcademicbatchActions | AlertActions>) => {
            const config: any = {
                header: {
                    "Content-Type": "application/json",
                },
            };

            try {
                const { data } = await axios.post(`academicbatch/updatecurrentyear`, body, config);
                dispatch({
                    type: types.ACADEMICBATCH_REGISTER_SUCCESS,
                    payload: data,
                });
                dispatch<any>(
                    setAlert({
                        msg: "Academic Batch Register successful!",
                        status: 200,
                        alertType: "success",
                    })
                );
                dispatch<any>(getAcademicbatchs({ orgId: orgId }));
            } catch (error: any) {
                dispatch({ type: types.ACADEMICBATCH_REGISTER_FAIL });
                dispatch<any>(
                    setAlert({
                        msg: error?.response?.data,
                        status: error?.response?.status,
                        alertType: "danger",
                    })
                );
            }
            // finally {
            //   setSubmitting(false);
            // }
        };