import useApi from 'components/hooks/useApi';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as AuthServices from "services/auth"
import types from "redux/actionTypes/user";
import { loadUser } from 'redux/actions/user';
import { setAlert } from 'redux/actions/alert';
const Auth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParam] = useSearchParams();
    const {action : googleAuthAction , data: googleAuthData, loading : googleAuthLoading, error : googleAuthError} = useApi(AuthServices.googleAuth, {initialLoad : true, error : true});

    function decodeData(encodedData) {
      const decodedString = atob(encodedData);
      const decodedArray = decodedString.split('').map(char => char.charCodeAt(0));
      const decoder = new TextDecoder();
      return JSON.parse(decoder.decode(new Uint8Array(decodedArray)));
    }
    
    
    useEffect(() => {
      const code = searchParam.get("code");
      const orgId = decodeData(searchParam.get("state"));
      const error =searchParam.get("error");

      if(!error){
        googleAuthAction({orgId, deviceType : "WebApp", code});
      } else {
        const errorDescription = searchParam.get("error_description");
        if(errorDescription){
          dispatch(setAlert({
            alertType : "danger",
            status : 500,
            msg : errorDescription
          }))
        } else {
          dispatch(setAlert({
            alertType : "danger",
            status : 500,
            msg : "Login Failed"
          }))
        }
      }
    }, [searchParam])

    useEffect(() => {
      if(!googleAuthLoading){
        if(!googleAuthError){
          if(googleAuthData){
            dispatch({
              type: types.USER_LOGIN_SUCCESS,
              payload: googleAuthData?.data,
            });
          }

          // Dispatch to store token and user details (similar to login process).
          dispatch<any>(loadUser(null));
          navigate("/dashboard", {replace : true });
        } else {
          navigate("/login", {replace : true });
        }
      }
    }, [googleAuthLoading, googleAuthData, googleAuthError])
  return (
    <div style={{width: "50%", margin : "auto"}}>
      {googleAuthLoading ? <div className="d-flex justify-content-center mt-5 pt-5"><span className="spinner-border spinner-border-md mt-3 theme-primary-color" style={{width: "200px", height: "200px"}}></span></div> : 
      googleAuthError ? "Login Failed" : "Login successful redirecting to dashboards..."}
    </div>
  )
}

export default Auth