import axios from './axios';

type additionalData = {
  branchId ?: string,
  branchAcademicBatchId ?: string
}
export const setUserAuthToken = (token: string,loginType?:string, data?: additionalData): void => {
  const routeBreakdowns = window.location.pathname?.split("/")[2];
  const route_path = routeBreakdowns;
  if (token) {
    axios.defaults.headers.common['x-user-auth-token'] = token;
    if(loginType == "branch"){
      let obj={
        route_path,
        branchId:localStorage.branchId,branchAcademicBatchId:localStorage.branchAcademicBatchId,moduleId:localStorage.routeId,roleId:localStorage.roleId,createdBy:localStorage.userId
      }
      var encoded = btoa(JSON.stringify(obj))
      axios.defaults.headers.common['TimelineKey'] = encoded;
    } else if (data?.branchAcademicBatchId && data?.branchId){
      let obj={
        route_path,
        branchId:data?.branchId,branchAcademicBatchId:data?.branchAcademicBatchId,moduleId:localStorage?.routeId,roleId:localStorage?.roleId,createdBy:localStorage?.userId
      }
      var encoded = btoa(JSON.stringify(obj));
      axios.defaults.headers.common['TimelineKey'] = encoded;
    } 
    else if(loginType == "organization"){
      let obj={
        route_path,
        orgId:localStorage?.orgId,academicBatchId:localStorage?.academicBatchId,moduleId:localStorage?.routeId,roleId:localStorage?.roleId,createdBy:localStorage?.userId
      }
      var encoded = btoa(JSON.stringify(obj));
      axios.defaults.headers.common['TimelineKey'] = encoded;
    } 
  } else {
    delete axios.defaults.headers.common['x-user-auth-token'];
  }
};

export const setHeader = () => ({
  header: {
    'Content-Type': 'application/json',
  },
});
