import React from 'react';
import * as Sentry from '@sentry/react';

import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserAuthToken } from 'utils/headers';
import { loadUser } from 'redux/actions/user';
import { validateDomain } from "redux/actions/organization";
import NotFound from 'components/not-found/NotFound';
import Home from 'pages/Home';
import Alert from 'layouts/alert/Alert';
import LoaderComponent from 'components/Loader';
import LowBandwidth from 'components/not-found/LowBandwidth';
if (localStorage.user__token) setUserAuthToken(localStorage.user__token);
let guestRoleId: any = null;
if (localStorage.guestRoleId) guestRoleId = localStorage.guestRoleId;


const App: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if(!window.location.pathname.includes("/forms")){
      dispatch<any>(loadUser(guestRoleId));
      const [subdomain] = window.location.hostname.split('.');
      if(subdomain !== "dev" && subdomain !== "app")
        dispatch(validateDomain({subdomain : subdomain}));
    }
  }, [dispatch]);

  const [isNetworkConnected, setNetworkConnected] = React.useState(true);
  const [isNetworkSlow, setNetworkSlow] = React.useState(false);
  React.useEffect(() => {
    const handleNetworkChange = () => {
      setNetworkConnected(navigator.onLine);
    };

    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, []);


  return (
    <Sentry.ErrorBoundary fallback={<NotFound />}>
      {isNetworkConnected ? 
        isNetworkSlow ? (
            <LowBandwidth type={""}/>
        ) : 
        (<Router>
        <div className="app">
          <Home/>
          <Alert />
          <LoaderComponent />
        </div>
      </Router>) : 
      <LowBandwidth type={"NETWORK"}/>}
    </Sentry.ErrorBoundary>
  );
};
export default Sentry.withProfiler(App);
