import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css
import '@fortawesome/fontawesome-free/css/all.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import App from 'App';
import 'theme.css';
import 'index.css';
import 'resoinsive.css';
import Store from 'redux/store';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === 'production' ? 1.0 : 0.0,
  environment: process.env.REACT_APP_ENVIRONMENT
});
ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
    >
      <Provider store={Store}>
        <App />
      </Provider>
    </GoogleReCaptchaProvider>
  </React.StrictMode >,
  document.getElementById('root')
);
