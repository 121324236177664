
import types from "redux/actions/types";

const initialState = {
    staffHolidays: [],
    loading: false,
    error: ''
};
export const staffShiftsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.CREATE_STAFFHOLIDAYS_FAIL:
            return {
                ...state,
                error: action?.payload,
            };
        case types.GET_STAFFHOLIDAYS:
            return {
                ...state,
                loading: false,
                staffHolidays: action?.payload,
            };
       
        default:
            return state
    }
}
export default staffShiftsReducer;